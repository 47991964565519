<template>
  <div>
    <div style="margin-left: 20px; margin-right: 20px;">
      <a href="https://eclerk.io" target="_blank" style="text-decoration: none;"><span class="font-dosis-title" style="color: black; font-size: 32px; font-weight: bold;">Eclerk</span></a>
      <span v-if="onlinePaiement.facture.mission.client.collaborateur.company.logo_thumbnail" style="float: right; margin-top:10px;">
        <img :src="baseUrl + onlinePaiement.facture.mission.client.collaborateur.company.logo_thumbnail" class="mt-2" style="width: 100%; max-width: 200px;"/>
      </span>
      <span v-else style="float: right; margin-top:10px; font-size: 24px; font-weight: bold;">
        {{onlinePaiement.facture.mission.client.collaborateur.company.company_name}}
      </span>
    </div>
    <div class="d-flex align-items-center" style="margin-top: 50px;">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="black">
      </loading>
      <CContainer>
        <CRow>
          <CCol>
            <h1> {{onlinePaiement.facture.mission.client.collaborateur.company.company_name}} - Paiement en ligne de votre facture </h1>
          </CCol>
        </CRow>

        <CCard class="mt-3">
          <CCardBody>
            <CRow>
              <CCol lg="3">
                <span> # Facture : </span> <strong> {{onlinePaiement.facture.facture_number}}</strong><br>
                <strong> {{onlinePaiement.facture.mission.client.name}} </strong><br>
                <strong> {{onlinePaiement.facture.mission.name}}</strong><br>
                <span> Référent : </span> <strong> {{onlinePaiement.facture.mission.referent.full_name}}</strong> <em>{{onlinePaiement.facture.mission.referent.user.username}}</em><br>
                <span> Date d'échéance : </span> <strong> {{$dayjs(onlinePaiement.facture.date_echeance).format('DD/MM/YYYY')}}</strong><br>
              </CCol>
              <CCol lg="9">
                <CRow class="mt-2 mt-lg-0">
                  <CCol class="text-lg-right text-center" sm="4">
                    <span> MONTANT HT A PAYER </span><br>
                    <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(onlinePaiement.facture.montant_total_ht).toFixed(2) - parseFloat(onlinePaiement.facture.remise_totale_ht).toFixed(2)).toFixed(2))}} €</strong>
                  </CCol>
                  <CCol class="text-lg-right text-center" sm="4">
                    <span> MONTANT TTC A PAYER </span><br>
                    <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(onlinePaiement.facture.montant_total_ttc).toFixed(2))}} €</strong>
                  </CCol>
                  <CCol class="text-lg-right text-center" sm="4">
                    <span> RESTE A PAYER </span><br>
                    <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(onlinePaiement.facture.montant_total_ttc).toFixed(2) - parseFloat(onlinePaiement.facture.montant_total_paid).toFixed(2)).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-md-right">
                <CButton v-if="onlinePaiement.is_paid" shape="pill" color="outline-primary" @click="downloadPaiement()"> <CIcon name="cil-cloud-download" /> Télécharger votre reçu de paiement </CButton>
                <br v-if="onlinePaiement.is_paid" class="d-sm-block d-md-none">
                <CButton class="ml-0 ml-md-2 mt-2 mt-md-0" shape="pill" color="outline-primary" @click="downloadFacture"> <CIcon name="cil-cloud-download" /> Télécharger votre facture en pdf</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>


        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong v-if="!onlinePaiement.is_paid"> Montant à payer : {{formatThousandSeparatorNumber(parseFloat(onlinePaiement.montant).toFixed(2))}} €</strong>
              <strong v-else><CIcon class="text-success" name="cil-check-circle" /> Montant payé : {{formatThousandSeparatorNumber(parseFloat(onlinePaiement.montant).toFixed(2))}} €</strong>
            </slot>
          </CCardHeader>

          <CCardBody>
            <CRow v-if="!onlinePaiement.is_paid">
              <CCol>
                <CInput
                  id="cardholder-name"
                  label="Nom du titulaire de la carte"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="newCardHolderName" type="text"
                  maxlength="200"
                  placeholder="Nom du titulaire"
                  @input="$v.newCardHolderName.$touch()"
                  :isValid="$v.newCardHolderName.$dirty ? !$v.newCardHolderName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 200 caractères"
                >
                </CInput>
                <label>Votre numéro de carte bancaire</label>
                <div ref="card"></div>
                <small id="card-errors" class="text-danger"></small>
                <CButton
                  color="outline-primary"
                  shape="pill" block class="px-4 mt-4"
                  @click="handleSubmit"
                  :disabled="$v.newCardHolderName.$invalid || paiementInProcess">
                    Payer ma facture en ligne de {{onlinePaiement.montant}} €
                </CButton>
              </CCol>
            </CRow>

            <CRow v-else>
              <CCol class="text-center">
                <p>
                  <strong style="font-size: 22px;">Féliciations, votre facture a bien été payée ! 👍</strong>
                </p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CRow>
          <CCol class="text-center">
            <a class="Link Link--primary" href="https://stripe.com" target="_blank">
              <span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Powered by
                <svg class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15">
                  <g fill-rule="evenodd">
                    <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z">

                    </path>
                  </g>
                </svg>
              </span>
            </a><br>
            <a class="Link mr3 Link--primary" href="https://stripe.com/checkout/terms" target="_blank">
              <span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"> Terms </span>
            </a>
            <a class="Link Link--primary" href="https://stripe.com/privacy" target="_blank">
              <span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"> Privacy </span>
            </a>
          </CCol>
        </CRow>



        <!--<CRow class="justify-content-center">
          <CCol md="8">
            <h1 class="text-center"></h1>
            <p class="mt-4 text-muted">Entrez l'email associé à votre compte pour recevoir le lien de réinitialisation</p>

            <CInput
              placeholder="Email *"
              type="email"
              maxlength="249"
              v-model="resetPasswordEmail"
              @input="$v.resetPasswordEmail.$touch()"
              :isValid="$v.resetPasswordEmail.$dirty ? !$v.resetPasswordEmail.$error : null"
            >
              <template #description>
                <small v-if="errorEmailMessage" class="text-danger"> {{errorEmailMessage}} </small>
              </template>
            </CInput>

            <CRow class="mt-4">
              <CCol class="text-center">
                <CButton
                  type="submit"
                  :color="resetPasswordButtonStyle" block class="px-4"
                  shape="pill"
                  size="lg" :disabled="$v.resetPasswordEmail.$invalid || passwordResetingInProcess">
                  <CSpinner size="sm" label="Reset password spinner" v-if="passwordResetingInProcess"></CSpinner> {{ resetPasswordButtonText }}
                </CButton>
              </CCol>
            </CRow>
            <div class="mt-3">
                <small>Pas encore de compte ?
                <router-link to="/pages/inscription">Inscrivez-vous gratuitement</router-link></small>
            </div>
          </CCol>
        </CRow>-->
      </CContainer>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { stripePublishableKey } from '@/variables/localVariables'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import renderMixins from '@/mixins/renderMixins'

import { APIDisconnectedService } from '@/api/APIDisconnected'

const apiDisconnectedService = new APIDisconnectedService()

var card = undefined;

export default {
  name: 'OnlinePaiement',
  components: {
    Loading,
  },
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {

      stripe: null,
      elements: null,

      baseUrl: apiBaseUrl,

      isOnlinePaiementLoading: false,
      onlinePaiement: {
        created_at: '',
        id: '',
        is_paid: false,
        is_relance: false,
        last_date_relance: '',
        montant: 0,
        paiement_date: null,
        random_url: '',
        relance_frequence: 'WEEK',

        facture: {
          date_echeance: '',
          date_facturation: null,
          delai_paiement: '30',
          facturation_address: '',
          facture_number: 0,
          has_been_sent: false,
          id: '',
          is_active: true,
          montant_total_ht: 0,
          montant_total_ttc: 0,
          montant_total_paid: 0,
          remise_totale_ht: 0,

          mission: {
            id: '',
            name: '',

            client : {
              name: '',
              collaborateur: {
                company: {
                  company_name: '',
                  logo_thumbnail: '',
                  stripe_connected_account_id: ''
                }
              }
            },

            referent: {
              full_name: '',

              user: {
                username: ''
              }
            }
          }
        }


      },

      isCreatingPaymentIntent: false,
      paymentIntentClientSecret: '',

      // PAIEMENT
      newCardHolderName: '',
      paiementInProcess: false,

      isConfirmingPaymentIntent: false

    }
  },
  computed: {
    isLoading () {
      if (this.isOnlinePaiementLoading || this.isCreatingPaymentIntent || this.paiementInProcess || this.isConfirmingPaymentIntent) {
        return true
      }
      return false
    },
  },

  validations: {
    newCardHolderName: {
      required,
      maxLength: maxLength(199),
      minLength: minLength(2)
    }
  },

  beforeDestroy() {
    card.destroy(this.$refs.card);
   },

  created: function() {
    this.$dayjs.locale('fr')
    this.getOnlinePaiementByUri()
  },

  methods: {

    getOnlinePaiementByUri () {
      this.isOnlinePaiementLoading = true
      apiDisconnectedService.getOnlinePaiementByUri(this.$route.params.uri)
      .then((result) => {
        this.onlinePaiement = result.data

        if (!result.data.is_paid) {
          this.createPaymentIntent()
          this.stripe = Stripe(stripePublishableKey, {
            stripeAccount: result.data.facture.mission.client.collaborateur.company.stripe_connected_account_id
          })
          this.elements = this.stripe.elements()

          card = this.elements.create('card');
          card.mount(this.$refs.card);
          card.on('change', this.showCardError)
        }

      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isOnlinePaiementLoading = false
      })
    },

    createPaymentIntent () {
      this.isCreatingPaymentIntent = true
      apiDisconnectedService.createPaymentIntent(this.$route.params.uri)
      .then((result) => {
        this.paymentIntentClientSecret = result.data.client_secret
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingPaymentIntent = false
      })
    },

    // ------- PAIEMENT ---------
    showCardError(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    },


    handleSubmit() {

      this.paiementInProcess = true

      if (!this.stripe || !this.elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      this.stripe.confirmCardPayment(this.paymentIntentClientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: this.newCardHolderName
          }
        }
      })
      .then((result) => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.paiementInProcess = false
          this.showCardError(result)
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.paiementInProcess = false
            this.confirmPaymentIntent(result.paymentIntent.id)
          }
        }
      })
      .catch(() => {
      })
    },

    confirmPaymentIntent (payment_intent_id) {
      this.isConfirmingPaymentIntent = true
      apiDisconnectedService.confirmPaymentIntent(this.$route.params.uri, payment_intent_id)
      .then((result) => {
        this.onlinePaiement = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isConfirmingPaymentIntent = false
      })
    },

    downloadFacture () {
      apiDisconnectedService.disconnectedClientDownloadFacture(this.onlinePaiement.facture.id, this.$route.params.uri)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const factureName = 'Facture-' + this.onlinePaiement.facture.facture_number + '-' + this.onlinePaiement.facture.mission.client.name  + '-' + this.onlinePaiement.facture.date_facturation + '.pdf'
          link.setAttribute('download', factureName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

    downloadPaiement () {
      apiDisconnectedService.disconnectedClientDownloadPaiement(this.onlinePaiement.paiement.id, this.$route.params.uri)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const paiementName = 'Recu-' + this.onlinePaiement.paiement.date + '-' + this.onlinePaiement.paiement.client_name + '.pdf'
          link.setAttribute('download', paiementName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

  }
}
</script>

<style>
.Link--primary {
    color: #0074d4;
}
.Link {
    text-decoration: none;
    cursor: pointer;
}
.Text-color--gray400 {
    color: rgba(26,26,26,.5);
}
.Text-fontWeight--400 {
    font-weight: 400;
}
.Text-fontSize--12 {
    font-size: 12px;
}
.Text {
    margin: 0;
}
</style>
