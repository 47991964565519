import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIDisconnectedService {

  getOnlinePaiementByUri (uri) {
    const url = apiBaseUrl + `/get-online-paiement-by-uri/` + String(uri)
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.get(
      url,
      headers
    )
  }

  createPaymentIntent (uri) {
    const url = apiBaseUrl + `/create-paiement-intent/` + String(uri)
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url, {},
      headers
    )
  }

  confirmPaymentIntent (uri, payment_intent_id) {
    const url = apiBaseUrl + `/confirm-paiement-intent/` + String(uri)
    var headers = {
      'Content-Type': 'application/json'
    }
    var data = {
      'paid_paiement_intent_id': payment_intent_id
    }
    return axios.put(
      url, data,
      headers
    )
  }

  disconnectedClientDownloadFacture (facture_pk, uri) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/disconnected-client-download-facture/' + facture_pk + '/' + String(uri)
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  disconnectedClientDownloadPaiement (paiement_pk, uri) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/disconnected-client-download-paiement/' + paiement_pk + '/' + String(uri)
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }
}
